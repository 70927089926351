import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = ({ id, title, date, link, featured_media_src_url }) => {
  // Convert the date string to a Date object
  const dateObj = new Date(date);

  // Extract day, month, and year
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
  const year = dateObj.getFullYear();

  // Format as DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;

  return (
    <div className='blog-card'>
      <div className='card-image'>
        <Link to={link}>
          <img src={featured_media_src_url} className='img-fluid w-100' alt='blog' />
        </Link>
      </div>
      <div className='blog-content'>
        <p className='date'>{formattedDate}</p>
        <h5 className='title'>
          <Link to={link}>{title}</Link>
        </h5>
      </div>
    </div>
  );
};

export default BlogCard;
