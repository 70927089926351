export const services = [
{
    title: "Free Shipping",
    tagline: "From all orders over ₹299",
    image: "/images/service.png",
},
{
    title: "Daily Offers",
    tagline: "Save upto 25% off",
    image: "/images/service-02.png",
},
// {
//     title: "Support 24/7",
//     tagline: "Shop with an expert",
//     image: "/images/service-03.png",
// },
{
    title: "Affordable Prices",
    // tagline: "Get Factory Default Price",
    image: "/images/service-04.png",
},
{
    title: "Secure Payments",
    tagline: "100% Protected Payments",
    image: "/images/service-05.png",
},
];