import React, { useEffect, useState } from 'react';
import BreadCrumb from '../component/BreadCrumb';
import Container from '../component/Container';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { base_url } from '../utils/axiosConfig';

const OrderView = () => {

  const { _id } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
        try {
            const response = await axios.get(`${base_url}user/order/${_id}`);
            setOrderDetails(response.data);
            console.log('Order Details Response:', response.data); // Log response data
            } catch (error) {
                console.error('Error fetching order details:', error);
            }
        };

        if (_id) {
            fetchOrderDetails();
        }
    }, [_id]);

 return (
    <>
    <BreadCrumb title="Order Details" />
    <Container class1="cart-wrapper home-wrapper-2 py-5">
    <div className='row'>
        <div className='col-lg-12'>
            <div className='card'>
                <div className='card-body'>
                    <h2 className='mt-4'>Order Details</h2>
                    {orderDetails ? (
                    <>
                    <h5 className='mt-5'>Order: {orderDetails.orderID}</h5>
                    <div className='row'>
                    <div className='col-3 m-4' style={{ border: '1px solid grey', borderRadius: '1px', padding: '15px' }}>
                    <b>Customer</b>: {orderDetails.user.name}
                    </div>
                    <div className='col-3 m-4' style={{ border: '1px solid grey', borderRadius: '1px', padding: '15px' }}>
                        <b>Date</b>: {new Date(orderDetails.createdAt).toLocaleDateString()}
                    </div>
                    <div className='col-3 m-4' style={{ border: '1px solid grey', borderRadius: '1px', padding: '15px' }}>
                    <b>Status</b>: {orderDetails.orderStatus}
                    </div>
                    </div>

        <table className="table table-bordered mt-4">
            <thead>
            <tr>
            <th>Product Name</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Total Price</th>
            </tr>
            </thead>
            <tbody>
            {orderDetails.orderItems.map((item, index) => (
            <tr key={index}>
            <td>{item.product.title}</td>
            <td>{item.quantity}</td>
            <td>
            {item.product.simpleProductDetails && item.product.simpleProductDetails[0]?.price ? (
                `₹ ${item.product.simpleProductDetails[0]?.price}`
                ) : item.product.variableProductDetails && item.product.variableProductDetails[0]?.attribute[0]?.price ? (
                `₹ ${item.product.variableProductDetails[0]?.attribute[0]?.price}`
                ) : ('Price not available')}
            </td>
            <td>{`₹ ${item.quantity * (item.product.simpleProductDetails ? item.product.simpleProductDetails[0]?.price : item.product.variableProductDetails[0]?.attribute[0]?.price)}`}</td>
            </tr>
            ))}
            </tbody>
            </table>

            <div className='row'>
            <div className='col-6 mt-4'>
            <div style={{ border: '1px solid grey', borderRadius: '1px', padding: '15px' }}>
                <h5><b>Shipping Address:</b></h5>
                <p className='mt-3'><b>FirstName</b>: {orderDetails.shippingInfo.firstName}</p>
                <p><b>LastName</b>: {orderDetails.shippingInfo.lastName}</p>
                <p><b>Address</b>: {orderDetails.shippingInfo.address}</p>
                <p><b>City</b>: {orderDetails.shippingInfo.city}</p>
                <p><b>State</b>: {orderDetails.shippingInfo.state}</p>
                <p><b>Pincode</b>: {orderDetails.shippingInfo.pincode}</p>
                </div>
                </div>
                </div>
              </>
            ) : (<p>Order details not available.</p>)}
                </div>
            </div>
        </div>
    </div>
</Container>
</>
);
}

export default OrderView;
