import React from 'react';
import axios from 'axios';

function Payment() {
  const handleCreateOrder = async () => {
    try {
      // Step 1: Generate Shiprocket token
      const loginResponse = await axios.post('https://apiv2.shiprocket.in/v1/external/auth/login', {
        email: 'sejal.beejbhandarho@gmail.com',
        password: 'Sustainable@123'
      });

      const token = loginResponse.data.token;
      localStorage.setItem('shiprocketToken', token);

      console.log("Shiprocket API token:", token);

      // Step 2: Create Shiprocket order
      const shiprocketOrderPayload = {
        order_id: '2277747', // Generate a random order ID
        order_date: '2024-05-30 2:50', // Format to 'YYYY-MM-DD HH:mm'
        pickup_location: 'Khargone',
        channel_id: '',
        comment: 'Reseller: M/s Goku',
        billing_customer_name: 'Raksha',
        billing_last_name: 'Barve',
        billing_address: 'House 221B, Khargone',
        billing_address_2: 'Near Hokage House',
        billing_city: 'Khargone',
        billing_pincode: '451001',
        billing_state: 'MP',
        billing_country: 'India',
        billing_email: 'raksha@gamil.com',
        billing_phone: '9876543210',
        shipping_is_billing: true,
        shipping_customer_name: '',
        shipping_last_name: '',
        shipping_address: '',
        shipping_address_2: '',
        shipping_city: '',
        shipping_pincode: '',
        shipping_country: '',
        shipping_state: '',
        shipping_email: '',
        shipping_phone: '',
        order_items: [
          {
            name: 'Test Product',
            sku: 'AA123',
            units: 10,
            selling_price: '900',
            discount: '',
            tax: '',
            hsn: 441122
          }
        ],
        payment_method: 'Prepaid',
        shipping_charges: 0,
        giftwrap_charges: 0,
        transaction_charges: 0,
        total_discount: 0,
        sub_total: 9000,
        length: 10,
        breadth: 15,
        height: 20,
        weight: 2.5
      };

      // Log the payload to verify its structure
      console.log("Shiprocket Order Payload:", shiprocketOrderPayload);

      const createOrderResponse = await axios.post(
        'https://apiv2.shiprocket.in/v1/external/orders/create/adhoc',
        shiprocketOrderPayload,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );

      console.log("Shiprocket create order response:", createOrderResponse.data);
      alert('Order created successfully!');
    } catch (apiError) {
      console.error("Error making Shiprocket API call:", apiError);
      alert('Error creating order. Please try again later.');
    }
  };

  return (
    <div>
      <button onClick={handleCreateOrder}>Create Order</button>
    </div>
  );
}

export default Payment;
