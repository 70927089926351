import React from 'react';
import Container from '../component/Container';
import Meta from '../component/Meta';
import BreadCrumb from '../component/BreadCrumb';
import { TbFileDollar } from "react-icons/tb";
import { GrNotes } from "react-icons/gr";
import { FaShippingFast } from "react-icons/fa";
import { FaArrowsLeftRight } from "react-icons/fa6";

function SellOnline() {
  return (
    <div>
      <Meta title={"Sell Online"} />
      <BreadCrumb title='Sell-Online' />
      <Container class1='policy-wrapper py-5 home-wrapper-2'>
        <h2 style={{ textAlign: 'center' }}>The lowest cost of doing business in the industry</h2>
        <p className='sell-text'>With the most competitive rate card in the industry, transparent delivery charges based on the weight and dimensions of your products and a small fixed fee, selling on Sustainable is highly cost-efficient.</p>
        <h2 className='sell-head'>SUSTAINABLE MARKETPLACE FEE STRUCTURE</h2>
        
        <div className="icon-content-container mt-5 mb-5">
          <div className="row mt-3">
            <div className="col-3 text-center">
              <div className="icon-container">
              <TbFileDollar className="icon" />
              </div>
              <p className='i-text'>Referral fee</p>
              <p>Percentage of Order item value depends on category & sub-category</p>
            </div>
            <div className="col-3 text-center">
              <div className="icon-container">
               <GrNotes className='icon'/>
              </div>
              <p className='i-text'>Fixed fee</p>
              <p>A small fee that Sustainable charges on all transactions</p>
            </div>
            <div className="col-3 text-center">
              <div className="icon-container">
                <FaShippingFast className='icon'/>
              </div>
              <p className='i-text'>Shipping fee</p>
              <p>Calculated on the basis of product weight and shipping location</p>
            </div>
            <div className="col-3 text-center">
              <div className="icon-container">
                <FaArrowsLeftRight className='icon'/>
              </div>
              <p className='i-text'>Transaction fee</p>
              <p>Payment gateway or cash collection charges for every sale</p>
            </div>
          </div>
        </div>
        <div className="row mt-5">
            <div className="col-3 text-center mt-3">
              <p className='i-para'>Settlement Amount</p>
              <p className='mt-4'>Percentage of Order item value depends on category & sub-category</p>
            </div>
            <div className="col-3 text-center mt-3">
              <p className='i-para'>Order Item Value</p>
              <p className='mt-4'>A small fee that Sustainable charges on all transactions</p>
            </div>
            <div className="col-3 text-center mt-3">
              <p className='i-para'>Marketplace Fee</p>
              <p className='mt-4'>Calculated on the basis of product weight and shipping location</p>
            </div>
            <div className="col-3 text-center mt-3">
              <p className='i-para'>GST</p>
              <p className='mt-4'>Payment gateway or cash collection charges for every sale</p>
            </div>
          </div>
          <hr width="100%" size="5" color='black'></hr>
          <div className='row mt-4'>
            <div className='col-12'>
            <h2 style={{ textAlign: 'center' }}>TRANSACTION FEE</h2>
            <p className='mt-3' style={{ textAlign: 'center' }}>A small transaction fee is charged for all prepaid orders. For prepaid orders, the transaction fee is determined based on the payment gateway used. The final selling price of a product, which includes the amount paid by the customer and any applicable shipping charges, is used to calculate this fee. Prepaid orders, the fee is calculated using the final selling price of the product.It’s important to note that the transaction fee applies to all sellers on Sustainable Basket, regardless of their category or tier.Please let me know if there’s anything else I can help you with!</p>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-12'>
            <h2 style={{ textAlign: 'center' }}>FIXED FEES</h2>
            <p className='mt-3' style={{ textAlign: 'center' }}>A fixed fee is charged for every successful sale transaction. A successful sale transaction refers to an order that has been successfully delivered to the customer. This fee is not applicable in the case of returns. The fixed fee varies based on the order item value and is not dependent on the category or seller tier. Regardless of the product category or the tier of the seller, the fee will be determined by the order item value.It’s important to note that the fixed fee is only applicable to successful sale transactions. If you have any further questions, feel free to ask!</p>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-12'>
            <h2 style={{ textAlign: 'center' }}>REFERRAL FEES</h2>
            <p className='mt-3' style={{ textAlign: 'center' }}>A referral fee is charged for every successful sale transaction. A successful sale transaction refers to an order that has been successfully delivered to the customer. This fee is not applicable in the case of returns. The referral fee varies based on the category or seller tier. It’s important to note that the referral fee is only applicable to successful sale transactions. If you have any further questions, feel free to ask!</p>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-12'>
            <h2 style={{ textAlign: 'center' }}>TRANSACTION FEE</h2>
           <p className='mt-3' style={{ textAlign: 'center' }}>We require all sellers to deliver products to customers using our designated logistics partners. To provide a seamless selling experience and ensure the best possible customer service, we deduct the shipping cost from the selling price before making a payment to you as the seller.The shipping fee is calculated based on the actual weight or volumetric weight of the item, whichever is higher. This calculation method allows us to account for items that may be lightweight but occupy a significant amount of shipping space.By deducting the shipping cost upfront, we aim to simplify the selling process for you as a seller and provide transparent pricing to customers. This ensures a smoother experience for everyone involved.If you have any more questions or concerns, please let me know!</p>
            </div>
          </div>
      </Container>
      <div className="text-center mt-4">
        <iframe 
          src="https://sustainablebasket.com/vendor-price-calculator/" 
          height="700" 
          width="100%" 
          style={{ maxWidth: '1200px' }} 
          title="Iframe Example">
        </iframe>
      </div>
    </div>
  )
}

export default SellOnline;
