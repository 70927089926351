import React from 'react'
import Container from '../component/Container'
import { Link } from 'react-router-dom'
import CustomInput from '../component/CustomInput'

function SigninWithMobile() {
  return (
    <div>
  <Container class1='login-wrapper py-5 home-wrapper-2'>
    <div className='row'>
    <div className='col-lg-12 col-md-6 col-sm-6'>
    <div className='auth-card '>
        <h3 className='text-center mb-5'>Login/Register</h3>
        <form action='' className='d-flex flex-column gap-15'>
        <CustomInput type='email' name='' placeholder='Enter Phone Number' />
    <div>
        <div className='mt-5 d-flex justify-content-center gap-15 align-items-center'>
        <button className='button signup border-0' type='submit'> Proceed</button>
    </div>
    </div>
    </form>
    </div>
    </div>
    </div>
 </Container>
</div>
  )
}

export default SigninWithMobile
