import React from 'react'
import Meta from '../component/Meta'
import BreadCrumb from '../component/BreadCrumb'
import { useFormik } from 'formik';
import * as yup from "yup"
import Container from '../component/Container';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { base_url } from '../utils/axiosConfig';

const shippingAddress = yup.object({
    firstName: yup.string().required("FirstName is Required"),
    lastName: yup.string().required("LastName is Required"),
    address: yup.string().required("Address Details are Required"),
    state: yup.string().required("State is Required"),
    city: yup.string().required("City is Required"),
    country: yup.string().required("Country is Required"),
    pincode: yup.number().required("Pincode is Required"),
});

function Address() {
  const { id } = useParams(); 
//  console.log("usrid",id)
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      address: "",
      state: "",
      city: "",
      country: "",
      pincode: "",
      other: "",
    },
    validationSchema: shippingAddress,
    onSubmit: async (values) => {
      try {
        console.log('Submitting values:', values); // Log values for debugging

        const response = await axios.put(`${base_url}user/edit-user/${id}`, {
          saveAddress: {
            country: values.country,
            firstName: values.firstName,
            lastName: values.lastName,
            address: values.address,
            other: values.other,
            state: values.state,
            city: values.city,
            pincode: values.pincode,
           
          }
        });

        console.log('Response:', response.data); // Log response for debugging
        toast.success('Address updated successfully!');
      } catch (error) {
        console.error('Error updating address:', error); // Log error for debugging
        toast.error('Failed to update address');
      }
    },
  });
  return (
   <> 
    <Meta title={"Address"}/>
    <BreadCrumb title='Address'/>
    <Container class1='checkout-wrapper py-5 home-wrapper-2'>
    <div className='row mt-2'>
    <div className='col-lg-7 col-sm-12 col-md-12'>
    <div className='checkout-left-data'>
    <h3 className='website-name'>Add Address</h3>
    <form onSubmit={formik.handleSubmit} className='d-flex gap-15 flex-wrap justify-content-between mt-4'>
    <div className='w-100'>
      <select name='country' className='form-control form-select' value={formik.values.country}
        onChange={formik.handleChange} onBlur={formik.handleBlur}>
      <option value='' disabled>Select Country</option>
      <option value='India'>India</option>
      </select>
      <div className='errors ms-2 my-1'>
       {formik.touched.country && formik.errors.country}
      </div>
      </div>
      <div className='flex-grow-1'>
        <input type='text' placeholder='First Name' className='form-control' name='firstName' value={formik.values.firstName}
          onChange={formik.handleChange} onBlur={formik.handleBlur}/>
      <div className='errors ms-2 my-1'>
          {formik.touched.firstName && formik.errors.firstName}
      </div>
      </div>
      <div className='flex-grow-1'>
      <input type='text' placeholder='Last Name' className='form-control' name='lastName' value={formik.values.lastName}
       onChange={formik.handleChange} onBlur={formik.handleBlur}/>
      <div className='errors ms-2 my-1'>
        {formik.touched.lastName && formik.errors.lastName}
      </div>
      </div>
      <div className='w-100'>
        <input type='text' placeholder='Address' className='form-control' name='address' value={formik.values.address}
        onChange={formik.handleChange} onBlur={formik.handleBlur}/>
      <div className='errors ms-2 my-1'>
         {formik.touched.address && formik.errors.address}
      </div>
      </div>
      <div className='w-100'>
        <input type='text' placeholder='Apartment, Suite, etc' className='form-control' name='other' value={formik.values.other}
         onChange={formik.handleChange} onBlur={formik.handleBlur}/>
     </div>
    <div className='flex-grow-1'>
      <input type='text' placeholder='City' className='form-control' name='city' value={formik.values.city}
       onChange={formik.handleChange} onBlur={formik.handleBlur}/>
    <div className='errors ms-2 my-1'>
        {formik.touched.city && formik.errors.city}
    </div>
    </div>
       <div className='flex-grow-1'>
        <select name='state' className='form-control form-select' value={formik.values.state}
         onChange={formik.handleChange} onBlur={formik.handleBlur}>
          <option value='' disabled>Select State</option>
          <option value='Madhya Pradesh'>Madhya Pradesh</option>
          <option value='Maharashtra'>Maharashtra</option>
          <option value='Gujarat'>Gujarat</option>
          <option value='Rajasthan'>Rajasthan</option>
        </select>
      </div>
    <div className='flex-grow-1'>
        <input type='text' placeholder='ZipCode' className='form-control' name='pincode' value={formik.values.pincode}
           onChange={formik.handleChange} onBlur={formik.handleBlur}/>
    <div className='errors ms-2 my-1'>
       {formik.touched.pincode && formik.errors.pincode}
    </div>
    </div>
    <div className='mt-3'>
        <button className='button' type='submit'>
        Save Address
       </button>
    </div>
    </form>
   </div>
  </div>
 </div>
</Container>
    </>
  )
}

export default Address
