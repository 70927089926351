import React, { useEffect } from 'react'
import Meta from '../component/Meta'
import BreadCrumb from '../component/BreadCrumb'
import Container from '../component/Container'
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineLogout, AiOutlineShoppingCart } from "react-icons/ai";
import { FaRegUserCircle } from "react-icons/fa";
import { FaHeart } from 'react-icons/fa';
import { HiOutlineTruck } from "react-icons/hi2";
import { useDispatch, useSelector } from 'react-redux';
import { getUserProductWishlist } from '../features/user/userSlice';
import { addToWishlist } from '../features/product/productSlice';

function MyWishlist() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const wishlistState = useSelector(state => state?.auth?.wishlist?.wishlist || []);  
    useEffect(() => {
      dispatch(getUserProductWishlist());
    }, [dispatch]);
  
    const removeWishlist = (id) => {
      dispatch(addToWishlist(id));
      setTimeout(() => {
        dispatch(getUserProductWishlist());
      }, 300);
    };

      // Function to truncate title
  const truncateTitle = (title, maxLength) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + '...';
    }
    return title;
  };

    // Handle navigation to product details
    const handleViewDetails = (slug) => {
        navigate(`/product/${slug}`);
      };

  return (
    <div>
    <Meta title="My Wishlist" />
    <BreadCrumb title="My Wishlist" />
    <Container class1="cart-wrapper home-wrapper-2 py-5">
        <div className="row">
          {/* Column for the list */}
          <div className="col-lg-2 col-md-4">
            <div className="list-group">
              <Link to="/my-profile" className="list-group-item">
                <FaRegUserCircle className="icon-order" /> My Profile
              </Link>
              <hr className="divider" />
              <Link to="/my-orders" className="list-group-item">
                <HiOutlineTruck className="icon-order" /> My Orders
              </Link>
              <hr className="divider" />
              <Link to="/my-wishlist" className="list-group-item active-link">
                <FaHeart className="icon-order" /> My Wishlist
              </Link>
              <hr className="divider" />
              <Link to="/logout" className="list-group-item">
                <AiOutlineLogout className="icon-order" /> Logout
              </Link>
            </div>
          </div>
          {/* Column for the content */}
          <div className="col-lg-10 col-md-6">
            {wishlistState.length === 0 ? (
              <div className="wishlist-container">
                <img src="/images/wishlist-no.png" alt="Wishlist Icon" className="wishlist-icon mt-4" />
                <p className='fs-2 mt-2'>NO ITEMS IN THE WISHLIST</p>
                <p className="wishlist-message mt-2">
                  Add now, Buy Later.<br />
                  Save your favourite beauty items here!
                </p>
                <Link to="/our-store" className="start-shopping-link mt-3">Start Shopping</Link>
              </div>
            ) : (
              <div className="row">
                {wishlistState.map((item, index) => (
                  <div key={index} className='col-lg-4 col-md-6 col-sm-12'>
                    <div className='wish-card position-relative'>
                      <img 
                        onClick={() => removeWishlist(item?._id)}
                        src='/images/cross.svg' 
                        alt='cross' 
                        className='position-absolute cross img-fluid'
                      />
                      <div className='wish-card-image bg-white'>
                        <img src={item?.images[0][0]} className='img-fluid d-block mx-auto' alt='product'/>
                      </div>
                      <div className='py-3 px-3'>
                        <h5 className='title'>{truncateTitle(item?.title, 62)}</h5>
                        {(item.simpleProductDetails && item.simpleProductDetails[0]?.price) &&
                        (item.variableProductDetails && item.variableProductDetails[0]?.attribute[0]?.price) ? (
                          <p className='price'>
                            ₹ {item.simpleProductDetails[0]?.price} / ₹ {item.variableProductDetails[0]?.attribute[0]?.price}
                          </p>
                        ) : item.simpleProductDetails && item.simpleProductDetails[0]?.price ? (
                          <p className='price'>₹ {item.simpleProductDetails[0]?.price}</p>
                        ) : item.variableProductDetails && item.variableProductDetails[0]?.attribute[0]?.price ? (
                          <p className='price'>₹ {item.variableProductDetails[0]?.attribute[0]?.price}</p>
                        ) : (
                          <p className='price'>Price not available</p>
                        )}
                        <button className='add-to-cart-btn'  onClick={() => handleViewDetails(item?.slug)}>View Details</button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Container>
  </div>
  )
}

export default MyWishlist
