import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { getAProduct } from '../features/product/productSlice';
import { CgMenuGridO } from "react-icons/cg";
import { FaRegUser, FaSearch } from "react-icons/fa";
import { TbShoppingBagPlus } from 'react-icons/tb';
import { CiHeart } from 'react-icons/ci';
import { IoMdLogOut } from 'react-icons/io';
import { getUserCart } from '../features/user/userSlice';
import { Dropdown } from 'react-bootstrap'; 
import { FcGoogle } from "react-icons/fc";

const Header = () => {
  const dispatch = useDispatch();
  const cartState = useSelector(state => state?.auth?.cartProducts);
  const productState = useSelector(state => state?.product?.product);
  const [total, setTotal] = useState(0);
  const [productOpt, setProductOpt] = useState([]);
  const [paginate, setPaginate] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const authState = useSelector(state => state?.auth); // Access auth state

  
  // const getUser = async () => {
  //   try {
  //     const response = await axios.get(`${base_url}login/success`, { withCredentials: true });
  //     setUserData(response.data.user);
  //     setIsLoggedIn(true);
  //     toast.success("User Login successfully");
  //   } catch (error) {
  //     setIsLoggedIn(false);
  //     console.error("Error fetching user", error);
  //     // toast.error("Error fetching user");
  //   }
  // };

  // useEffect(() => {
  //   getUser();
  // }, []);

  // const loginwithgoogle = () => {
  //   window.open(`${base_url}auth/google`, "_self");
  // };
  
  // const logout = () => {
  //   window.open(`${base_url}logout`, "_self");
  // };

  // // Determine the display name
  // const displayName = isLoggedIn
  //   ? (userData.displayName || authState?.user?.name || 'User Name')
  //   : 'Sign In';
  // Fetch user id
  const customerData = localStorage.getItem('customer');
  const customer = customerData ? JSON.parse(customerData) : null;
  let id = '';
  if (customer && customer._id) {
    id = customer._id;
  } else {
    console.log('User ID not found in localStorage');
  }

  // Fetch cart data when the component mounts and whenever the cartState changes
  useEffect(() => {
    dispatch(getUserCart()); // Fetch the cart data when the component mounts
  }, [dispatch]);

  // Calculate total price whenever cartState changes
  useEffect(() => {
    if (Array.isArray(cartState)) {
      let sum = 0;
      cartState.forEach(item => {
        if (item?.productId?.simpleProductDetails && item?.productId?.simpleProductDetails[0]?.price) {
          sum += item?.productId?.simpleProductDetails[0]?.price * item?.quantity;
        } else if (item?.productId?.variableProductDetails && item?.productId?.variableProductDetails[0]?.attribute[0]?.price) {
          sum += item?.productId?.variableProductDetails[0]?.attribute[0]?.price * item?.quantity;
        }
      });
      setTotal(sum);
    }
    else {
      console.warn('cartState is not an array:', cartState);
    }
  }, [cartState]);

  // Fetch product options whenever productState changes
  useEffect(() => {
    if (Array.isArray(productState)) {
      const data = productState.map((element, index) => ({
        id: index,
        prod: element?.slug,
        name: element?.title
      }));
      setProductOpt(data);
    } else {
      console.warn('productState is not an array:', productState);
    }
  }, [productState]);
  
 // Assuming authState.user will be null if not logged in
//  const isLoggedIn = authState?.user !== null;

 const handleLogout = () => {
  localStorage.clear();
  window.location.reload(); // This reloads the page
  navigate('/'); // Redirect to the home page
};

const handleSearch = (query) => {
  if (query && query.length > 0) {
    navigate(`/our-store?search=${query}`);
  }
};

const handleSearchKeyPress = (e) => {
  if (e.key === 'Enter') {
    handleSearch(e.target.value);
  }
};

const handleSearchIconClick = () => {
  const query = searchRef.current?.state?.text; // Accessing the input value via ref
  handleSearch(query);
};

  // Handle dropdown toggle
  const handleDropdownToggle = () => {
    setShowDropdown(prev => !prev);
  };
  
  return (
    <>
   {/* <header className='header-top-strip py-3'>
    <div className='container-xxl'>
      <div className='row'>
        <div className='col-lg-12 col-md-12 col-sm-12'>
        <p className='text-white mb-0 font-weight-bold text-center'>Welcome to Our Sustainable Store</p>
        </div>
       <div className='col-xl-5 col-lg-3 col-md-4 col-sm-12'>
        <p className='text-end text-white mb-0'><a href='#' className='text-white'>Customer Care</a></p>
        </div>
        <div className='col-xl-1 col-lg-1 col-md-3 col-sm-12'>
        <p className='text-end text-white mb-0'> <a href='#' className='text-white'>FAQ</a></p>
        </div> 
      </div>
    </div>
   </header> */}
<header className='header-upper py-3'>
  <div className='container-xxl'>
    <div className='row align-items-center'>
      <div className='col-lg-3 col-md-3 col-sm-12'>
        <h5>
          <Link to='/'>
            <img src='/images/s-basket-greeen.png' alt='Icon' name='logo' className='logo' />
          </Link>
        </h5>
      </div>
     
      <div className='col-lg-4 col-md-3 col-sm-12 d-flex align-items-center'>
        <Link to='/become-a-seller' style={{ fontWeight: 'bold', textDecoration: 'none', color: 'black' }}>
        Become a Seller
        </Link>
      </div>

      <div className='col-lg-3 col-md-6 col-sm-12 d-flex'>
        <div className="input-group">
          <Typeahead 
            ref={searchRef} // Attach the ref to the Typeahead component
            id="search-input"
            onPaginate={() => console.log('Results paginated')}
            onChange={(selected) => {
              if (selected.length > 0) {
                navigate(`/product/${selected[0]?.prod}`);
                dispatch(getAProduct(selected[0]?.prod));
              }
            }}
            options={productOpt}
            paginate={paginate}
            labelKey={"name"}
            minLength={2}
            placeholder="Search sustainablebasket.com"
            onKeyDown={handleSearchKeyPress}
          />        
          <span className="input-group-text p-2" id="basic-addon2" onClick={handleSearchIconClick} style={{ cursor: 'pointer' }}>
            <FaSearch className='fs-8' style={{ color: 'white' }} />
          </span>
        </div>
      </div>
      
      <div className='col-lg-2 col-md-3 col-sm-12 d-flex justify-content-end align-items-center'>
        <div className='header-upper-links d-flex align-items-center'>
        <Dropdown show={showDropdown} onToggle={handleDropdownToggle} onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
        <Dropdown.Toggle className='btn btn-success text-white d-flex align-items-center gap-0'style={{ backgroundColor: 'green' }}
          onClick={() => {setShowDropdown(prev => !prev); }}>
          <FaRegUser />
          {authState?.user ? authState?.user?.name : 'Sign In'}
        </Dropdown.Toggle>
        <Dropdown.Menu className={`dropdown-menu ${authState?.user ? 'logged-in-menu' : 'logged-out-menu'}`}>
          {authState?.user ? (
          <>
            <Dropdown.Item as={Link} to="/my-profile" className="dropdown-item-custom">
              <FaRegUser className="dropdown-icon" /> Profile
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/my-orders" className="dropdown-item-custom">
              <TbShoppingBagPlus className="dropdown-icon" /> Orders
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/my-wishlist" className="dropdown-item-custom">
              <CiHeart className="dropdown-icon" /> Wishlist
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout} className="dropdown-item-custom">
              <IoMdLogOut className="dropdown-icon" /> Logout
            </Dropdown.Item>
           </>
          ) : (
          <>
          <Dropdown.Item className="dropdown-item-custom text-center dropdown-item-custom-width">
            <h5>Login / Create Account</h5>
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/signup" className="dropdown-item-custom button-item">
            <span className="button-text">Sign in with Mobile/Email</span>
          </Dropdown.Item>
          <Dropdown.Item className="dropdown-item-custom button-item">
            <FcGoogle className="button-icon" />
            <span className="button-text">Google</span>
          </Dropdown.Item>
        </>
        )}
      </Dropdown.Menu>
    </Dropdown>
          
    <div className='d-flex align-items-center' style={{ marginLeft: '15px' }}>
      <Link to='/cart' className='d-flex align-items-center gap-10 text-white'>
        <div style={{ position: 'relative' }}>
         <img src="/images/cartt.png" style={{ width: '30px', height: '30px' }} alt='cart' />
          {/* Render badge only if cartState has items */}
           {cartState?.length > 0 && (
          <span className='badge' 
           style={{
            position: 'absolute', 
            top: '0', 
            right: '0', 
            transform: 'translate(50%, -50%)',
            backgroundColor: 'green',  // Set background color to green
            color: 'white',             // Set text color to white
          }}>
          {cartState.length}
        </span>
      )}
        </div>
      </Link>
    </div>
  </div>
</div>
</div>
</div>
</header>

   <div className="horizontal-line" style={{ backgroundColor: 'green', height: '2px' }}></div>
   <header className='header-bottom py-3'>
      <div className='container-xxl'>
        <div className='row'>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <div className='menu-bottom d-flex align-items-center gap-30'>
             <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle bg-transparent border-0 gap-15 d-flex align-items-center" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <CgMenuGridO className='' style={{ color: 'black', fontSize: '1em' }} />
                <span className='me-5 d-inline-block text-dark font-weight-bold'> Shop Categories</span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><Link to='/our-store' className="dropdown-item text-white" href="#">All</Link></li>
                  <li><Link to='/our-store' className="dropdown-item text-white" href="#">Beauty and Health</Link></li>
                  <li><Link to='/our-store' className="dropdown-item text-white" href="#">Clothing</Link></li>
                </ul>
              </div>
              </div>
              </div>
              {/* <div className='col-lg-9 col-md-6 col-sm-12'>
              <div className='menu-bottom d-flex align-items-center gap-30'>
              <div className='menu-link '>
                <div className='d-flex align-items-center gap-15 text-dark mt-1'>
                  <NavLink to="/" className='text-dark font-weight-bold'>Home</NavLink>
                  <NavLink to="/our-store" className='text-dark font-weight-bold'> Store</NavLink>
                  <NavLink to="/my-orders" className='text-dark font-weight-bold'>My Orders</NavLink>
                </div> 
              </div>
              </div>
           </div> */}
        
        </div>
      </div>
   </header>
   
    </>
  )
}

export default Header
