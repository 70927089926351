import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { base_url } from '../utils/axiosConfig';

function VendorVerify() {
    const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await fetch(`${base_url}vendor/verify-email?token=${token}`);
        const data = await response.json();
        if (data.status === 'success') {
          setStatus('success');
        } else {
          setStatus('error');
        }
      } catch (error) {
        setStatus('error');
      }
    };
    verifyEmail();
  }, [token]);
  return (
    <div>
    {status === 'loading' ? (
        <p className='text-center'>Verifying...</p>
      ) : status === 'success' ? (
        <>
          <img
            src="../images/right-icon.jpg"
            alt="icon"
            className="img-fluid mx-auto d-block mb-4"
            style={{ maxWidth: '150px' }} // Set your desired max width
          />
          <h1 className='text-center mb-4'>Thank You</h1>
          <p className='text-center mt-1 mb-5 font-weight-bold'>
            Congratulations! Your email has been verified. Please{' '}
            <a href="https://www.sustainablebasket.com/vendor">login</a>.
          </p>
        </>
      ) : (
        <>
          <img
            src="../images/error-icon.png"
            alt="icon"
            className="img-fluid mx-auto d-block mb-4"
            style={{ maxWidth: '150px' }} // Set your desired max width
          />
          <h1 className='text-center mb-4'>Verification Failed</h1>
          <p className='text-center mt-1 mb-5 font-weight-bold'>
            Sorry, the verification link is invalid or expired. Please try again.
          </p>
        </>
      )}
    </div>
  )
}

export default VendorVerify
