import React, { useEffect, useState } from 'react';
import Meta from '../component/Meta';
import BreadCrumb from '../component/BreadCrumb';
import Container from '../component/Container';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineLogout, AiOutlineShoppingCart } from "react-icons/ai";
import { FaRegUserCircle } from "react-icons/fa";
import { FaHeart } from 'react-icons/fa';
import { HiOutlineTruck } from "react-icons/hi2";
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../features/user/userSlice';

function NoOrderPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const orderState = useSelector(state => state?.auth?.getOrderProduct?.orders || []);
  
    useEffect(() => {
      dispatch(getOrders());
    }, [dispatch]);

    const handleViewDetails = (_id) => {
        navigate(`/order-details/${_id}`);
      };
          // Function to truncate title
  const truncateTitle = (title, maxLength) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + '...';
    }
    return title;
  };
  return (
    <div>
      <Meta title="My Orders" />
      <BreadCrumb title="My Orders" />
      <Container class1="cart-wrapper home-wrapper-2 py-5">
        <div className="row">
          {/* Column for the list */}
          <div className="col-lg-2 col-md-4">
            <div className="list-group">
              <Link to="/my-profile" className="list-group-item">
                <FaRegUserCircle className="icon-order" /> My Profile
              </Link>
              <hr className="divider" />
              <Link to="/my-orders" className="list-group-item">
                <HiOutlineTruck className="icon-order" /> My Orders
              </Link>
              <hr className="divider" />
              <Link to="/my-wishlist" className="list-group-item">
                <FaHeart className="icon-order" /> My Wishlist
              </Link>
              <hr className="divider" />
              <Link to="/logout" className="list-group-item">
                <AiOutlineLogout className="icon-order" /> Logout
              </Link>
            </div>
          </div>
          {/* Column for the content */}
          <div className="col-lg-10 col-md-6">
            <div className="content-container">
              <h4>My Orders</h4>
            </div>
            {orderState.length === 0 ? (
              <div className="cart-container">
                <AiOutlineShoppingCart className="cart-icon" />
                <p>No recent orders</p>
                <Link to="/our-store" className="start-shopping-link">Start Shopping</Link>
              </div>
            ) : (
          <div className="order-details-container">
            {orderState.map((order, index) => (
              <div key={index} className='order-card'>
              <h5 className='order-id'>Order ID: {order.orderID}</h5>
              <div className='order-items'>
              {order.orderItems.map((item, idx) => (
              <div key={idx} className='order-item row'>
                <div className='col-lg-2 col-md-2'>
                <img src={item.product?.images[0][0]} alt={item.product.title} className='img-fluid w-50' />
                </div>
                <div className='col-lg-6 col-md-6'>
                <h6 className='order-title mt-3'>{truncateTitle(item?.product?.title, 62)}</h6>
                {item?.product?.simpleProductDetails && item?.product?.simpleProductDetails[0]?.price ? (
                  <p className='order-price'>₹ {item?.product?.simpleProductDetails[0]?.price}</p>
                ) : item?.product?.variableProductDetails && item?.product?.variableProductDetails[0]?.attribute[0]?.price ? (
                <p className='order-price'>₹ {item?.product?.variableProductDetails[0]?.attribute[0]?.price}</p>
                ) : (
               <p className='order-price'>Price not available</p>
               )}
              </div>
              <div className='col-lg-4 col-md-4 mb-5'>
              <button className='view-details-btn' onClick={() => handleViewDetails(order?._id)} >
               View Details
              </button>
            </div>
          </div>
          ))}
        </div>
      </div>
      ))}
    </div>
)}
  </div>
  </div>
</Container>
</div>
  );
}

export default NoOrderPage;
