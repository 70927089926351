import React, { useEffect, useState } from 'react';
import BreadCrumb from '../component/BreadCrumb';
import Meta from '../component/Meta';
import { MdDelete } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import Container from '../component/Container';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCartProduct, getUserCart, updateCartProduct } from '../features/user/userSlice';
import { base_url } from '../utils/axiosConfig';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [productUpdateDetail, setProductUpdateDetail] = useState(null)
  const [totalAmount, setTotalAmount] = useState(0)
  const userCartState = useSelector(state=>state?.auth?.cartProducts)
  const [cartData, setCartData] = useState(null);
  console.log("Cartdata",cartData);
  console.log("userCartdata",userCartState);
  const isSmallScreen = useMediaQuery({ maxWidth: 576 });

  const generateOrRetrieveUserID = () => {
    let userID = localStorage.getItem('userID');
    console.log("GENRATE USERID",userID)
    return userID;
  };
  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const customer = localStorage.getItem("customer");
        if (customer) {
          dispatch(getUserCart());
        } else {
          const userID = localStorage.getItem('userID');
          console.log("userID:", userID); // Log the userID for debugging
          if (userID) {
            const response = await fetch(`${base_url}user/getcart?userID=${userID}`);
            if (!response.ok) {
              throw new Error('Failed to fetch cart data');
            }
            const data = await response.json();
            console.log("cart data:", data); // Log the cart data for debugging
            setCartData(data);
          }
        }
      } catch (error) {
        console.error('Error fetching cart data:', error);
      }
    };
    fetchCartData();
  }, [dispatch]);
  
  useEffect(() => {
    // Fetch user cart data if user is logged in
    const customer = localStorage.getItem('customer');
    if (customer) {
      dispatch(getUserCart());
    }
  }, [dispatch]);
  
// Update Quantity
useEffect(() => {
  if (productUpdateDetail !== null) {
    dispatch(updateCartProduct({ cartItemId: productUpdateDetail?.cartItemId, quantity: productUpdateDetail?.quantity }))
    setTimeout(() => {
      dispatch(getUserCart())
    }, 200)
  }
}, [productUpdateDetail]);

// Delete Cart Product
const deleteACartProduct = (id) => {
  dispatch(deleteCartProduct(id))
  setTimeout(() => {
    dispatch(getUserCart())
  }, 200)
}

useEffect(() => {
  let subtotal = 0;
  let cartItems = userCartState || cartData || [];
  if (!Array.isArray(cartItems)) {
    cartItems = [cartItems];
  }
  cartItems.forEach(item => {
    if (item?.productId?.simpleProductDetails && item?.productId?.simpleProductDetails[0]?.price) {
      subtotal += item?.productId?.simpleProductDetails[0]?.price * item?.quantity;
    } else if (item?.productId?.variableProductDetails && item?.productId?.variableProductDetails[0]?.attribute[0]?.price) {
      subtotal += item?.productId?.variableProductDetails[0]?.attribute[0]?.price * item.quantity;
    }
  });
setTotalAmount(subtotal);
}, [userCartState, cartData]);

const handleCheckout = () => {
  const customer = localStorage.getItem('customer');
  const userID = localStorage.getItem('userID');

  if (customer) {
    navigate('/checkout');
  } else if (userID) {
    toast.warning("Please Create Your Account")
    navigate('/login');
  } else {
    toast.warning("Please Create Your Account")
    navigate('/login');
  }
};

return (
  <>
    <Meta title={'Shopping Cart'} />
    <BreadCrumb title='Shopping Cart' />
    <Container class1='cart-wrapper home-wrapper-2 py-5'>
    <div className='row'>
      <div className='col-lg-12 col-md-6 col-sm-6'>
        <div className='cart-header py-3 d-flex justify-content-between align-items-center'>
          <h6 className='cart-col-1'>Product</h6>
          <h6 className='cart-col-2'>Price</h6>
          <h6 className='cart-col-3'>Quantity</h6>
          {!isSmallScreen && <h6 className='cart-col-4'>Total</h6>}
        </div>
        {(userCartState && userCartState.length > 0) && (userCartState.map((item, index) => (
        <div key={index} className='cart-data py-3 mb-2 d-flex justify-content-between align-items-center'>
            <div className='cart-col-1 gap-15 d-flex align-items-center'> 
            <div className={`w-25 ${isSmallScreen ? 'w-75' : ''}`}>
              {item?.productId?.images && item.productId?.images.length > 0 ? (
                <img src={item?.productId?.images[0][0]} className='img-fluid' alt='product' />
              ) : (
                <img src='default-image-url' className='img-fluid' alt='product' />
              )}
            </div>      
             {!isSmallScreen && (
          <div className={`w-75 ${isSmallScreen ? 'small-screen-title' : ''}`}>
              <p>{item?.productId ? item?.productId.title : 'Product Title Not Available'}</p>
            </div>
          )}
            </div>
             <div className='cart-col-2'>
              {(item.productId?.simpleProductDetails && item?.productId?.simpleProductDetails[0]?.price) &&
               (item?.productId?.variableProductDetails && item.productId?.variableProductDetails[0]?.attribute[0]?.price) ? (
                 <h5 className='price'>
                  ₹ {item.productId?.simpleProductDetails[0]?.price} / ₹ {item.productId?.variableProductDetails[0]?.attribute[0]?.price}
                </h5>
               ) : item?.productId?.simpleProductDetails && item?.productId?.simpleProductDetails[0]?.price ? (
                 <h5 className='price'> ₹ {item?.productId?.simpleProductDetails[0]?.price}</h5>
              ) : item?.productId?.variableProductDetails && item?.productId?.variableProductDetails[0]?.attribute[0]?.price ? (
                 <h5 className='price'>₹ {item.productId?.variableProductDetails[0]?.attribute[0]?.price}</h5>
               ) : (
                <h5 className='price'>Price not available</h5>
               )}
              </div>
              <div className={`cart-col-3 d-flex align-items-center gap-15 ${isSmallScreen ? 'w-25' : ''}`}>
              <div>
                  <input className='form-control' type='number' name={"quantity"+item?._id} min={1} max={5} id={"cart"+item?._id}
                    value={item?.quantity} onChange={(e)=>{setProductUpdateDetail({cartItemId:item?._id,quantity:e.target.value})}} />
                </div>
                <div>
                  <MdDelete onClick={()=>{deleteACartProduct(item?._id)}} className='text-danger' />
                </div>
              </div>
              {!isSmallScreen && (
              <div className='cart-col-4'>
               {(item.productId?.simpleProductDetails && item?.productId?.simpleProductDetails[0]?.price) &&
               (item.productId?.variableProductDetails && item?.productId?.variableProductDetails[0]?.attribute[0]?.price) ? (
               <h5 className='price'>
                  ₹ {item.productId?.simpleProductDetails[0]?.price * item.quantity} / ₹ {item?.productId?.variableProductDetails[0]?.attribute[0]?.price * item.quantity}
               </h5>
               ) : item.productId?.simpleProductDetails && item.productId?.simpleProductDetails[0]?.price ? (
                <h5 className='price'> ₹ {item.productId?.simpleProductDetails[0]?.price * item.quantity}</h5>
                 ) : item.productId?.variableProductDetails && item.productId?.variableProductDetails[0]?.attribute[0]?.price ? (
               <h5 className='price'>₹ {item.productId?.variableProductDetails[0]?.attribute[0]?.price * item.quantity}</h5>
               ) : (
               <h5 className='price'>Price not available</h5>
                )}
               </div>
              )}
        </div>
      )))  || (cartData && cartData.length > 0 && cartData.map((item, index) => (
        <div key={index} className='cart-data py-3 mb-2 d-flex justify-content-between align-items-center'>
            <div className='cart-col-1 gap-15 d-flex align-items-center'>
            <div className='w-25'>
            {item?.productId?.images && item.productId?.images.length > 0 ? (
             <img src={item?.productId?.images[0][0]} className='img-fluid' alt='product' />
              ) : (
              <img src='default-image-url' className='img-fluid' alt='product' />
              )}</div>       
             <div className='w-75'>
               <p>{item?.productId ? item?.productId.title : 'Product Title Not Available'} </p>
            </div>
            </div>
             <div className='cart-col-2'>
              {(item.productId?.simpleProductDetails && item?.productId?.simpleProductDetails[0]?.price) &&
               (item?.productId?.variableProductDetails && item.productId?.variableProductDetails[0]?.attribute[0]?.price) ? (
                 <h5 className='price'>
                  ₹ {item.productId?.simpleProductDetails[0]?.price} / ₹ {item.productId?.variableProductDetails[0]?.attribute[0]?.price}
                </h5>
               ) : item?.productId?.simpleProductDetails && item?.productId?.simpleProductDetails[0]?.price ? (
                 <h5 className='price'> ₹ {item?.productId?.simpleProductDetails[0]?.price}</h5>
              ) : item?.productId?.variableProductDetails && item?.productId?.variableProductDetails[0]?.attribute[0]?.price ? (
                 <h5 className='price'>₹ {item.productId?.variableProductDetails[0]?.attribute[0]?.price}</h5>
               ) : (
                <h5 className='price'>Price not available</h5>
               )}
              </div>
              <div className='cart-col-3 d-flex align-items-center gap-15'>
                 <div>
              <input className='form-control' type='number' name={"quantity"+item?._id} min={1} max={5} id={"cart"+item?._id}
               value={item?.quantity} onChange={(e)=>{setProductUpdateDetail({cartItemId:item?._id,quantity:e.target.value})}} />
              </div>
               <div>
                <MdDelete onClick={()=>{deleteACartProduct(item?._id)}} className='text-danger' />
              </div>
              </div>
              {!isSmallScreen && (
              <div className='cart-col-4'>
               {(item.productId?.simpleProductDetails && item?.productId?.simpleProductDetails[0]?.price) &&
               (item.productId?.variableProductDetails && item?.productId?.variableProductDetails[0]?.attribute[0]?.price) ? (
               <h5 className='price'>
                  ₹ {item.productId?.simpleProductDetails[0]?.price * item.quantity} / ₹ {item?.productId?.variableProductDetails[0]?.attribute[0]?.price * item.quantity}
               </h5>
               ) : item.productId?.simpleProductDetails && item.productId?.simpleProductDetails[0]?.price ? (
                <h5 className='price'> ₹ {item.productId?.simpleProductDetails[0]?.price * item.quantity}</h5>
                 ) : item.productId?.variableProductDetails && item.productId?.variableProductDetails[0]?.attribute[0]?.price ? (
               <h5 className='price'>₹ {item.productId?.variableProductDetails[0]?.attribute[0]?.price * item.quantity}</h5>
               ) : (
               <h5 className='price'>Price not available</h5>
                )}
               </div>
              )}
             </div>
     )))}

     </div>
     <div className='col-lg-12 col-md-6 col-sm-12 py-2 mt-4'>
      <div className='d-flex justify-content-between align-items-baseline'>
       <Link to='/our-store' className='button'>
          Continue To Shopping
        </Link>
        {totalAmount !== null && totalAmount !== 0 && (
           <div className='d-flex flex-column align-items-end'>
            <h5>SubTotal: ₹ {totalAmount} </h5>
            <p>Taxes and shipping calculated at checkout</p>
           <button className='button' onClick={handleCheckout}>
              Checkout
           </button>
           </div>
          )}
         </div>
        </div>
       </div>
      </Container>
    </>
  );
};

export default Cart;

