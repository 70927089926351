import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { BsLinkedin, BsGithub,BsYoutube, BsInstagram ,BsFacebook} from "react-icons/bs";
import { base_url } from '../utils/axiosConfig';
import { toast } from 'react-toastify';

const Footer = () => {
  const [email, setEmail] = useState('');

  const handleSubscribe = async () => {
    if (!email) {
      toast.error('Please enter a valid email address');
      return;
    }

    try {
      const response = await fetch(`${base_url}newsLatter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        toast.success('Successfully subscribed to the News Latter!');
        setEmail('');
      } else {
        const result = await response.json();
        if (response.status === 409) {
          toast.error('Email already subscribed');
        } else {
          toast.error(result.message || 'Failed to subscribe');
        }
      }
    } catch (error) {
      toast.error('An error occurred while subscribing');
    }
  };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

return (
  <>
<footer className='py-4'>
  <div className='container-xxl'>
    <div className='row align-items-center'>
       <div className='col-lg-5 col-md-6 '>
         <div className='footer-top-data d-flex gap-30 align-items-center'>
            <img src='/images/newsletter.png' alt='newletter'></img>
            <h2 className='mb-0 text-white fs-8'>EXCLUSIVE DEALS/OFFERS!</h2>
         </div>
         </div>
        <div className='col-lg-7 col-md-6 col-sm-12'>
        <div className="input-group">
          <input type="text" className="form-control py-1" placeholder="Your Email Address" aria-label="Your Email Address" aria-describedby="basic-addon2"
          value={email} onChange={(e) => setEmail(e.target.value)}/>
          <span className="input-group-text p-2" id="basic-addon2"  onClick={handleSubscribe} style={{ cursor: 'pointer' }}>Subscribe</span>
        </div>
      </div>
     </div>
    </div>
  </footer>
     
  <footer className='py-4'>
    <div className='container-xxl'>
      <div className='row'>
        <div className='col-lg-3 col-md-6 col-sm-12'>
          <h4 className='text-white mb-4'>Contact Us</h4>
          <div>
            {/* <address className='text-white fs-0'>Flat No.: 77, 78 Kalali Mohalla, <br/>Indore Madhya Pradesh <br/>
               Pincode :452001
            </address> */}
            {/* <a href='tel:+918754896588' className='mt-3 d-block mb-1 text-white'>
                +918754896588
            </a> */}
            <a href='mailto:support@sustainablebasket.com' className='mt-2 d-block mb-0 text-white'>
            support@sustainablebasket.com
            </a>
            <div className='social_icon d-flex align-items-center gap-30 mt-4'>
              <a className='text-white' href='https://www.facebook.com/sustainablesbasket/' >
              <BsFacebook className=' fs-4'/>
              </a>
              <a className='text-white' href='https://www.linkedin.com/in/sustainablebasket/' >
               <BsLinkedin className=' fs-4'/>
              </a>
              <a className='text-white' href='https://www.instagram.com/sustainablebasket.emart/' >
              <BsInstagram className=' fs-4'/>
              </a>
              <a className='text-white' href='https://www.youtube.com/channel/UCm_yUbBx6AsNlHhZmpvMljQ'>
                <BsYoutube className=' fs-4'/>
              </a>
            </div>
           </div>
        </div>
        <div className='col-lg-3 col-md-6 col-sm-12'>
          <h4 className='text-white mb-4'>Customer Service</h4>
           <div className='footer-links d-flex flex-column'>
              <Link to='customer-service' className='text-white py-2 mb-1' onClick={handleClick}>Customer Service</Link>
              <Link to='/transaction-service' className='text-white py-2 mb-1' onClick={handleClick}>Transaction Service</Link>
              <Link to='/technical-support' className='text-white py-2 mb-1' onClick={handleClick}>Technical Support</Link>
              <Link to='/term-condition' className='text-white py-2 mb-1' onClick={handleClick}>Term & Conditions</Link>
              <Link to='/privacy-policy' className='text-white py-2 mb-1' onClick={handleClick}>Privacy Policy</Link>
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <h4 className='text-white mb-4'>How To Buy</h4>
            <div className='footer-links d-flex flex-column'>
              <Link to='making-payments' className='text-white py-2 mb-1' onClick={handleClick}>Making Payments</Link>
              <Link to='delivery' className='text-white py-2 mb-1' onClick={handleClick}>Delivery option</Link>
              <Link to='buyer-protection' className='text-white py-2 mb-1' onClick={handleClick}>Buyer Protection</Link>
              <Link to='customer-faq' className='text-white py-2 mb-1' onClick={handleClick}>Customer FAQs</Link>
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <h4 className='text-white mb-4'>Inquiry</h4>
            <div className='footer-links d-flex flex-column'>
              <Link to='become-a-vendor' className='text-white py-2 mb-1' onClick={handleClick}>Become A Seller</Link>
              <Link to='affiliate' className='text-white py-2 mb-1' onClick={handleClick}>Affiliate</Link>
              <Link to='/contact' className='text-white py-2 mb-1' onClick={handleClick}>Contact</Link>
              {/* <Link to='/blogs' className='text-white py-2 mb-1'>Blog</Link> */}
              {/* <Link className='text-white py-2 mb-1'>Faq</Link> */}
            </div>
          </div>
        </div>
      </div>
     </footer>
     <footer className='py-3'>
      <div className='container-xxl'>
        <div className='row'>
          <div className='col-12'>
            <p className='text-center mb-0 text-white'>&copy; {new Date().getFullYear()}; Powerd by  Sustainable Basket E-Mart Pvt. Ltd. All rights reserved. </p>
          </div>
        </div>
      </div>
     </footer>
    </>
  )
}

export default Footer
