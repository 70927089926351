import React, { useState,useEffect } from 'react'
import ReactStars from "react-rating-stars-component";
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToWishlist } from '../features/product/productSlice';
import { toast } from 'react-toastify';

const ProductCard =(props) => {
    const { grid, data } = props;
    const dispatch = useDispatch();
    let location = useLocation();
    const [showFullTitle, setShowFullTitle] = useState(false);
    const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth <= 768);

  //  console.log(location);
    const addToWish = (id) =>{
      dispatch(addToWishlist(id))
      toast.success("Added to wishlist successfully");
    }
    useEffect(() => {
      const handleResize = () => {
          setIsMobileScreen(window.innerWidth <= 768);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

    const toggleTitle = () => {
      setShowFullTitle(!showFullTitle);
    };
    if (!data || !Array.isArray(data)) {
      // If data is not defined or not an array, you can return some default content or handle it accordingly
      return <p>No data available</p>
      }
      const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    
  return (
   <>
    {
     data.map((item, index)=> {
  return(
       <Link to={`/product/${item?.slug}`} key={index} className={`text-decoration-none ${
         location.pathname === "/our-store" ? (isMobileScreen ? 'gr-12' : `gr-${grid}`) :"col-lg-3" }`} onClick={handleClick}>       
       <div className='product-card position-relative'>
        {/* <div className='wishlist-icon position-absolute'>
          <button className='border-0 bg-transprent' onClick={(e)=>{   e.stopPropagation(); addToWish(item?._id)}}>
            <img src='/images/wish.svg' alt='wishlist'/>
          </button>
        </div> */}
        <div className='product-image'>
         <img src={item?.images[0][0]} className='img-fluid mx-auto' alt='product-image' />
        {/* <img src={item?.images[0][1]} className='img-fluid mx-auto' alt='product-image' /> */}
        </div>
        <div className='product-details'>
            <h6 className='brand mt-4'>{item?.brand}</h6>
            <h5 className='product-title' onClick={toggleTitle}>
            {showFullTitle ? item?.title : `${item?.title.slice(0, 50)}...`}
            </h5>
            <h5 className='price mt-3'> {(item?.simpleProductDetails && item?.simpleProductDetails[0]?.price) ? (
              item?.variableProductDetails && item?.variableProductDetails[0]?.attribute[0]?.price ? (
            <h5 className='price'>
              ₹ {item?.simpleProductDetails[0]?.price} / ₹ {item?.variableProductDetails[0]?.attribute[0]?.price}
            </h5>
           ) : (
            <h5 className='price'> ₹  {item?.simpleProductDetails[0]?.price}</h5>
             )
           ) : (
            item?.variableProductDetails && item?.variableProductDetails[0]?.attribute[0]?.price ? (
            <h5 className='price'>₹  {item?.variableProductDetails[0]?.attribute[0]?.price}</h5>
             ) : (
            <h5 className='price'>Price not available</h5>
            )
            )}</h5>
            <ReactStars count={5} size={24} value={item?.totalrating.toString()} edit={false} activeColor="#ffd700" />
           <p className={`description ${grid === 12 ? "d-block": "d-none"}`}
            dangerouslySetInnerHTML={{__html: item?.description}}
           ></p>
           </div>
          </div>
         </Link>
            )
          })
         }
   </>
  );
};

export default ProductCard;
